import styled from 'styled-components'
import { GOLD } from '../../../styles/styleConstants'

export const ContactFormContainer = styled.form`
    width: 500px;
`

export const ContactInputContainer = styled.div`
    display: inline-block;
    margin: 10px 0 10px 5%;
    width: ${props => (props.halfSize ? '45%' : '95%')};

    @media (max-width: 700px) {
        width: 100%;
        margin: 10px 0;
    }
`

export const ContactInputLabel = styled.label`
    display: flex;
    flex-direction: column;
`

export const ContactInput = styled.input`
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid ${GOLD};
    font-size: 1.25rem;
`

export const ContactSubmit = ContactInput.extend`
    background: ${GOLD};
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1.25rem;
    cursor: pointer;
`

export const ContactTextArea = styled.textarea`
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid ${GOLD};
    font-size: 1.25rem;
`

export const HiddenInput = styled.input`
    display: none;
`
