import React from 'react'
import PropTypes from 'prop-types'

import {
    PricesServicesContainer,
    PricesHeading,
    PricesParagraph
} from './Prices.style'
import { PricesService } from './prices/PricesService'

const Prices = ({ prices }) => {
    return (
        <section id="prices">
            <PricesHeading>{prices.prices_heading}</PricesHeading>
            <PricesParagraph>{prices.prices_paragraph}</PricesParagraph>
            <PricesServicesContainer>
                {renderPriceServices(prices.price_services)}
            </PricesServicesContainer>
        </section>
    )
}

const renderPriceServices = price_services =>
    price_services.map(service => (
        <PricesService {...service} key={service.service_heading} />
    ))

Prices.propTypes = {
    prices: PropTypes.shape({
        prices_heading: PropTypes.string.isRequired,
        prices_paragraph: PropTypes.string.isRequired,
        price_services: PropTypes.arrayOf(
            PropTypes.shape({
                service_heading: PropTypes.string.isRequired,
                price_ranges: PropTypes.arrayOf(
                    PropTypes.shape({
                        price: PropTypes.string.isRequired,
                        day_range: PropTypes.string.isRequired
                    })
                )
            })
        )
    })
}

export { Prices }
