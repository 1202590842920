import styled from 'styled-components'

import { HEADING_STYLE, PARAGRAPH_STYLE } from '../../styles/styleConstants'

export const PricesServicesContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0;
`

export const PricesHeading = styled.h1`
    font-size: ${HEADING_STYLE.FONT_SIZE};
    width: ${HEADING_STYLE.WIDTH};
    border-bottom: ${HEADING_STYLE.BORDER_BOTTOM};
    padding-bottom: ${HEADING_STYLE.PADDING_BOTTOM};
`

export const PricesParagraph = styled.p`
    font-size: ${PARAGRAPH_STYLE.FONT_SIZE};
    text-align: ${PARAGRAPH_STYLE.TEXT_ALIGN};
    width: 100%;
`
