import styled from 'styled-components'

export const ImageHeader = styled.header`
    height: 80vh;
    background-size: cover;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const HeadingContainer = styled.div`
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: rgba(133, 151, 132, 0.62);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const Heading = styled.h1`
    text-align: center;
    font-weight: lighter;
    font-size: 3.25rem;
    line-height: 5rem;
    color: white;
`
