import React from 'react'
import PropTypes from 'prop-types'

import { ImageHeader, Heading, HeadingContainer } from './Banner.style'

const Banner = ({ banner }) => {
    return (
        <ImageHeader id="banner" imgUrl={banner.background_img}>
            <HeadingContainer>
                <Heading>{banner.heading}</Heading>
            </HeadingContainer>
        </ImageHeader>
    )
}

Banner.propTypes = {
    banner: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        background_img: PropTypes.string.isRequired
    })
}

export { Banner }
