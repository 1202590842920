import React from 'react'
import PropTypes from 'prop-types'

import { LayoutContainer } from './Layout.style'

const Layout = ({ children }) => {
    return <LayoutContainer>{children}</LayoutContainer>
}

Layout.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element.isRequired)
}

export { Layout }
