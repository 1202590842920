import React from 'react'
import PropTypes from 'prop-types'

import { FooterContainer, CopyrightNotice } from './Footer.style'

const Footer = ({ copyright }) => {
    return (
        <FooterContainer>
            <CopyrightNotice>{copyright.copyright_notice}</CopyrightNotice>
        </FooterContainer>
    )
}

Footer.propTypes = {
    copyright: PropTypes.shape({
        copyright_notice: PropTypes.string.isRequired
    })
}

export { Footer }
