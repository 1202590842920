import React from 'react'
import Recaptcha from 'react-google-recaptcha'

import {
    ContactFormContainer,
    ContactInputContainer,
    ContactInputLabel,
    ContactInput,
    ContactTextArea,
    ContactSubmit,
    HiddenInput
} from './ContactForm.style'

const ensureSingleDigitsHaveLeadingZero = digit =>
    digit < 10 ? `0${digit}` : digit

const getTodaysDate = () => {
    const newDate = new Date()
    const dd = ensureSingleDigitsHaveLeadingZero(newDate.getDate())
    const mm = ensureSingleDigitsHaveLeadingZero(newDate.getMonth() + 1)
    const yyyy = newDate.getFullYear()

    return `${yyyy}-${mm}-${dd}`
}

const ContactForm = () => {
    return (
        <ContactFormContainer
            name="cass-ceremonies"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            data-netlify-recaptcha="true"
        >
            <HiddenInput type="hidden" name="bot-field" />
            <ContactInputContainer halfSize={true}>
                <ContactInputLabel>
                    Name
                    <ContactInput type="text" name="Name" required />
                </ContactInputLabel>
            </ContactInputContainer>
            <ContactInputContainer halfSize={true}>
                <ContactInputLabel>
                    Phone (optional)
                    <ContactInput type="text" name="Phone" />
                </ContactInputLabel>
            </ContactInputContainer>
            <ContactInputContainer>
                <ContactInputLabel>
                    Email
                    <ContactInput type="email" name="Email" required />
                </ContactInputLabel>
            </ContactInputContainer>
            <ContactInputContainer>
                <ContactInputLabel>
                    Ceremony Type
                    <ContactInput type="text" name="Ceremony" required />
                </ContactInputLabel>
            </ContactInputContainer>
            <ContactInputContainer>
                <ContactInputLabel>
                    Ceremony Location (if known)
                    <ContactInput type="text" name="Location" />
                </ContactInputLabel>
            </ContactInputContainer>
            <ContactInputContainer halfSize={true}>
                <ContactInputLabel>
                    Planned Date (if known)
                    <ContactInput
                        type="date"
                        name="Date"
                        min={getTodaysDate()}
                    />
                </ContactInputLabel>
            </ContactInputContainer>
            <ContactInputContainer>
                <ContactInputLabel>
                    Message
                    <ContactTextArea rows="7" name="Message" required />
                </ContactInputLabel>
            </ContactInputContainer>
            <ContactInputContainer>
                <Recaptcha sitekey="6LfYOYwUAAAAAM_rn6gzCKoR7_LyntcxPbyQ36gR" />
            </ContactInputContainer>
            <ContactInputContainer>
                <ContactSubmit type="submit" value="Submit" />
            </ContactInputContainer>
        </ContactFormContainer>
    )
}

export { ContactForm }
