import React from 'react'
import PropTypes from 'prop-types'

import { ContactHeading, ContactContainer } from './Contact.style'
import { ContactCard } from './contact/ContactCard'
import { ContactForm } from './contact/ContactForm'

const Contact = ({ contact }) => {
    return (
        <section id="contact">
            <ContactHeading>Contact</ContactHeading>
            <ContactContainer>
                <ContactCard contact_email={contact.contact_email} />
                <ContactForm />
            </ContactContainer>
        </section>
    )
}

Contact.propTypes = {
    contact: PropTypes.shape({
        contact_email: PropTypes.string.isRequired
    })
}

export { Contact }
