import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import vcFavicon from '../assets/images/vc.png'

const SEO = ({ site_meta_data }) => {
    const { title } = site_meta_data
    return (
        <Helmet>
            <title>{title}</title>
            <link rel="icon" type="image/png" href={vcFavicon} />
        </Helmet>
    )
}

SEO.propTypes = {
    site_meta_data: PropTypes.shape({
        title: PropTypes.string.isRequired
    })
}

export { SEO }
