import styled from 'styled-components'
import { GOLD, PARAGRAPH_STYLE } from '../../../styles/styleConstants'

export const ServiceCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 225px;
    background: ${GOLD};
    color: white;
    border-radius: 30px;
    margin: 50px 0;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

    @media (max-width: 700px) {
        width: 95%;
        margin: 2.5%;
    }
`

export const ServiceHeading = styled.h1`
    margin: 20px 30px;
    font-size: 2rem;
`

export const ServicePriceRange = styled.div`
    text-align: center;
`

export const ServicePrice = styled.h1`
    font-size: 2rem;
`

export const ServiceDayRange = styled.p`
    font-size: ${PARAGRAPH_STYLE.FONT_SIZE};
`
