import { injectGlobal } from 'styled-components'
import MuliLight from '../assets/fonts/Muli-Light.ttf'

export const addGlobalStyling = () => {
    injectGlobal`
      @font-face {
        font-family: 'Muli-Light'; 
        src: url(${MuliLight});      
      }

      body {
        margin: 0;
        font-family: 'Muli-Light', sans-serif;
      }      
    `
}
