import styled from 'styled-components'

import { PARAGRAPH_STYLE } from '../../styles/styleConstants'

export const AboutIntro = styled.article`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;

    @media (max-width: 700px) {
        flex-direction: column;
    }
`

export const ProfileImage = styled.img`
    border-radius: 100%;
    margin: 40px 7.5%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    width: 30%;

    @media (max-width: 700px) {
        width: 80%;
    }
`

export const AboutIntroParagraph = styled.p`
    width: 45%;
    font-size: ${PARAGRAPH_STYLE.FONT_SIZE};
    text-align: ${PARAGRAPH_STYLE.TEXT_ALIGN};

    @media (max-width: 700px) {
        width: 100%;
    }
`
