import React from 'react'
import PropTypes from 'prop-types'

import { AboutIntro, ProfileImage, AboutIntroParagraph } from './About.style'
import { AboutService } from './about/AboutService'

const About = ({ about }) => {
    return (
        <section id="about">
            <AboutIntro>
                <ProfileImage src={about.profile_img} />
                <AboutIntroParagraph>
                    {about.about_paragraph}
                </AboutIntroParagraph>
            </AboutIntro>

            {renderAboutServices(about.about_services)}
        </section>
    )
}

const renderAboutServices = aboutServices => {
    return aboutServices.map((aboutService, i) => {
        return (
            <AboutService
                {...aboutService}
                isReversed={i % 2 !== 0}
                key={aboutService.service_heading}
            />
        )
    })
}

About.propTypes = {
    about: PropTypes.shape({
        profile_img: PropTypes.string.isRequired,
        about_paragraph: PropTypes.string.isRequired,
        about_services: PropTypes.arrayOf(
            PropTypes.shape({
                service_heading: PropTypes.string.isRequired,
                service_img: PropTypes.string.isRequired,
                service_paragraph: PropTypes.string.isRequired
            })
        )
    })
}

export { About }
