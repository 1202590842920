import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import { MenuBar, MenuBarLinks, HomeLogo, BurgerMenu } from './Navbar.style'

export class Navbar extends Component {
    state = {
        menuOpen: false
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            menuOpen: !prevState.menuOpen
        }))
    }

    render() {
        return (
            <MenuBar menuOpen={this.state.menuOpen}>
                <HomeLogo>
                    <AnchorLink href="#banner">VC</AnchorLink>
                </HomeLogo>
                <BurgerMenu onClick={this.toggleMenu}>&#9776;</BurgerMenu>
                <MenuBarLinks menuOpen={this.state.menuOpen}>
                    <AnchorLink offset="100" href="#about">
                        about
                    </AnchorLink>
                </MenuBarLinks>
                <MenuBarLinks menuOpen={this.state.menuOpen}>
                    <AnchorLink offset="100" href="#prices">
                        prices
                    </AnchorLink>
                </MenuBarLinks>
                <MenuBarLinks menuOpen={this.state.menuOpen}>
                    <AnchorLink offset="100" href="#contact">
                        contact
                    </AnchorLink>
                </MenuBarLinks>
            </MenuBar>
        )
    }
}
