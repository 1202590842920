import styled, { css } from 'styled-components'
import { GOLD } from '../styles/styleConstants'

export const MenuBar = styled.nav`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: space-around;

    ${props =>
        props.menuOpen &&
        css`
            flex-wrap: wrap;
        `};
`

const MenuBarItems = styled.li`
    margin: 20px;
    padding: 5px;
    list-style-type: none;
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;

    a {
        color: black;
        text-decoration: none;
        transition: inherit;
    }
`

export const MenuBarLinks = MenuBarItems.extend`
    a {
        position: relative;

        :before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -5px;
            background-color: ${GOLD};
            left: 0;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.2s ease-in-out 0s;
        }

        :hover:before {
            visibility: visible;
            transform: scaleX(1);
        }
    }

    @media (max-width: 700px) {
        display: ${props => (props.menuOpen ? 'block' : 'none')};
        ${props =>
            props.menuOpen &&
            css`
                width: 100%;
                text-align: center;
                margin: 0;
                padding: 10px;
            `};
    }
`

export const HomeLogo = MenuBarItems.extend`
    margin-right: auto;
    border: solid 1px black;
    border-radius: 100%;

    :hover {
        background: ${GOLD};
        border-color: ${GOLD};

        a {
            color: white;
        }
    }
`

export const BurgerMenu = MenuBarItems.extend`
    display: none;

    @media (max-width: 700px) {
        display: block;
    }
`
