import styled from 'styled-components'
import { HEADING_STYLE, PARAGRAPH_STYLE } from '../../../styles/styleConstants'

export const AboutServiceArticle = styled.article`
    display: flex;
    justify-content: space-between;
    flex-direction: ${props => (props.isReversed ? 'row-reverse' : 'row')};
    margin: 100px 0;

    @media (max-width: 700px) {
        flex-direction: column;
    }
`

export const AboutServiceImg = styled.img`
    width: 45%;
    align-self: center; // usually defaults to 'stretch' causing the image not to scale properly
    border-radius: 10px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);

    @media (max-width: 700px) {
        width: 100%;
    }
`

export const AboutServiceTextSection = styled.div`
    width: 45%;

    @media (max-width: 700px) {
        width: 100%;
        margin: 0;
    }
`

export const AboutServiceHeading = styled.h2`
    font-size: ${HEADING_STYLE.FONT_SIZE};
    width: ${HEADING_STYLE.WIDTH};
    border-bottom: ${HEADING_STYLE.BORDER_BOTTOM};
    padding-bottom: ${HEADING_STYLE.PADDING_BOTTOM};
    margin: 0 auto 0 0;
`

export const AboutServiceParagraph = styled.p`
    font-size: ${PARAGRAPH_STYLE.FONT_SIZE};
    text-align: ${PARAGRAPH_STYLE.TEXT_ALIGN};
`
