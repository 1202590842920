import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import { SEO } from '../components/SEO'
import { Navbar } from '../components/Navbar'
import { Layout } from '../components/Layout'
import { Footer } from '../components/Footer'
import { About, Banner, Contact, Prices } from '../components/home'
import { addGlobalStyling } from '../styles/globalStyle'

addGlobalStyling()

const Home = ({ data }) => {
    const { frontmatter } = data.allMarkdownRemark.edges[0].node

    return (
        <div>
            <SEO site_meta_data={frontmatter.site_meta_data} />
            <Navbar />
            <Banner banner={frontmatter.banner} />
            <Layout>
                <About about={frontmatter.about} />
                <hr />
                <Prices prices={frontmatter.prices} />
                <hr />
                <Contact contact={frontmatter.contact} />
            </Layout>
            <Footer copyright={frontmatter.copyright} />
        </div>
    )
}

Home.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            banner: PropTypes.object.isRequired,
                            about: PropTypes.object.isRequired,
                            prices: PropTypes.object.isRequired,
                            contact: PropTypes.object.isRequired,
                            copyright: PropTypes.object.isRequired,
                            site_meta_data: PropTypes.object.isRequired
                        })
                    })
                }).isRequired
            )
        })
    })
}

export default Home

export const query = graphql`
    query {
        allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/markdown/home.md/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        banner {
                            heading
                            background_img
                        }
                        about {
                            profile_img
                            about_paragraph
                            about_services {
                                service_heading
                                service_img
                                service_paragraph
                            }
                        }
                        prices {
                            prices_heading
                            prices_paragraph
                            price_services {
                                service_heading
                                price_ranges {
                                    price
                                    day_range
                                }
                            }
                        }
                        contact {
                            contact_email
                        }
                        copyright {
                            copyright_notice
                        }
                        site_meta_data {
                            title
                        }
                    }
                }
            }
        }
    }
`
