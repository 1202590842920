import styled from 'styled-components'

import { LIGHT_GREY } from '../styles/styleConstants'

export const FooterContainer = styled.footer`
    background: ${LIGHT_GREY};
    display: flex;
    justify-content: center;
    align-items: center;
`

export const CopyrightNotice = styled.p`
    margin: 30px;
    font-size: 0.75rem;
`
