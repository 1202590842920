export const GOLD = '#dcc511'
export const LIGHT_GREY = '#EFEFEF'

export const HEADING_STYLE = {
    FONT_SIZE: '2.25rem',
    BORDER_BOTTOM: `1px solid ${GOLD}`,
    WIDTH: 'fit-content',
    PADDING_BOTTOM: '3px'
}

export const PARAGRAPH_STYLE = {
    FONT_SIZE: '1.25rem',
    TEXT_ALIGN: 'justify'
}
