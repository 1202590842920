import React from 'react'
import PropTypes from 'prop-types'

// prettier-ignore
import {
    AboutServiceArticle,
    AboutServiceHeading,
    AboutServiceImg,
    AboutServiceParagraph,
    AboutServiceTextSection
} from "./AboutService.style"

const AboutService = ({
    service_heading,
    service_img,
    service_paragraph,
    isReversed
}) => {
    return (
        <AboutServiceArticle isReversed={isReversed}>
            <AboutServiceTextSection>
                <AboutServiceHeading>{service_heading}</AboutServiceHeading>
                <AboutServiceParagraph>
                    {service_paragraph}
                </AboutServiceParagraph>
            </AboutServiceTextSection>
            <AboutServiceImg src={service_img} />
        </AboutServiceArticle>
    )
}

AboutService.propTypes = {
    service_heading: PropTypes.string.isRequired,
    service_img: PropTypes.string.isRequired,
    service_paragraph: PropTypes.string.isRequired,
    isReversed: PropTypes.bool.isRequired
}

export { AboutService }
