import styled from 'styled-components'

export const ContactCardContainer = styled.div`
    margin-top: 10px;
    height: 100px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

    @media (max-width: 700px) {
        width: 100%;
        margin-bottom: 30px;
        text-align: center;
    }
`

export const ContactEmail = styled.p`
    margin: 10px;
`

export const ContactFacebook = styled.img`
    margin: 10px;
    width: 30px;
`
