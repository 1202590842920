import React from 'react'
import PropTypes from 'prop-types'
import {
    ServiceCard,
    ServiceHeading,
    ServicePriceRange,
    ServiceDayRange,
    ServicePrice
} from './PricesService.style'

const PricesService = ({ service_heading, price_ranges }) => {
    return (
        <ServiceCard>
            <ServiceHeading>{service_heading}</ServiceHeading>
            {renderPriceRanges(price_ranges)}
        </ServiceCard>
    )
}

const renderPriceRanges = price_ranges => {
    return price_ranges.map(price_range => {
        return (
            <ServicePriceRange key={price_range.day_range}>
                <ServicePrice>{price_range.price}</ServicePrice>
                <ServiceDayRange>{price_range.day_range}</ServiceDayRange>
            </ServicePriceRange>
        )
    })
}

PricesService.propTypes = {
    service_heading: PropTypes.string.isRequired,
    price_ranges: PropTypes.arrayOf(
        PropTypes.shape({
            price: PropTypes.string.isRequired,
            day_range: PropTypes.string.isRequired
        })
    )
}

export { PricesService }
