import React from 'react'
import PropTypes from 'prop-types'

import {
    ContactCardContainer,
    ContactEmail,
    ContactFacebook
} from './ContactCard.style'
import fbLogo from '../../../assets/images/facebook-logo.svg'

const ContactCard = ({ contact_email }) => {
    return (
        <ContactCardContainer>
            <ContactEmail>{contact_email}</ContactEmail>
            <a
                href="https://www.facebook.com/Cass-Ceremonies-372106173589574"
                rel="noopener noreferrer"
                target="_blank"
            >
                <ContactFacebook src={fbLogo} />
            </a>
        </ContactCardContainer>
    )
}

ContactCard.propTypes = {
    contact_email: PropTypes.string.isRequired
}

export { ContactCard }
